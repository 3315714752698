/* DYSON OVERWRITING CSS */
.pfc-toggle.toggle_small label {
    height: 20px;
    width: 36px;
    min-width: 36px;
}
/* SWITCH RADIO BUTTON */
.cc_switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 23px;
    /* display: none; */
    cursor: pointer;
}

/* Hide default HTML checkbox */
.cc_switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
/* The slider_pillow */
.slider_pillow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

label input[type="checkbox"] + span.slider_pillow:before {
    position: absolute;
    content: "";
    height: 19px;
    width: 19px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: 0 !important;
}

input:checked + .slider_pillow {
    background-color: #2bd313;
}

input:focus + .slider_pillow {
    box-shadow: 0 0 1px #2bd313;
}

input:checked + .slider_pillow:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
}

/* Rounded slider_pillows */
.slider_pillow.round {
    border-radius: 23px;
}

.slider_pillow.round:before {
    border-radius: 50%;
}
